<template>
  <NavQuestion v-bind:background="'bg-basket'" v-bind:questionOptions="true">
    <template v-slot:contents>
      <div v-show="showWill">
        <div class="col-lg-6 col-md-8 pb60">
          <div class="will-view-holder" id="pdf" ref="pdf"></div>
        </div>
        <div class="col-lg-4 col-lg-offset-2 col-md-4">
          <div class="viewwill-btn-wrapper">
            <a
              :href="pdfDownloadURI"
              class="btn btn-medium btn--dark"
              download
              target="_blank"
            >
              <span class="text">Download Will</span>
            </a>
          </div>
          <div class="viewwill-btn-wrapper">
            <div
              class="btn btn-medium btn--dark will-nav"
              v-on:click="nextPage"
              v-bind:disabled="false"
            >
              <span class="text">Next Page</span>
            </div>
          </div>
          <div class="viewwill-btn-wrapper">
            <div
              class="btn btn-medium btn--dark will-nav"
              v-on:click="previousPage"
              v-bind:disabled="false"
            >
              <span class="text">Previous Page</span>
            </div>
          </div>
          <div class="viewwill-btn-wrapper">
            <div
              class="btn btn-medium btn--dark will-nav"
              v-on:click="
                $router.push('/home').catch((error) => {
                  console.log(error)
                })
              "
              v-bind:disabled="false"
            >
              <span class="text">Edit</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </NavQuestion>
</template>

<script>
import { http } from '@/services'
import NavQuestion from '@/common/ui/NavQuestion'
import range from 'lodash/range'

export default {
  name: 'DocumentsFinalWill',
  components: {
    NavQuestion
  },
  created() {
    this.willId = this.$route.params.id
  },
  watch: {
    will: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          // if (!value.pdf) this.$store.dispatch('logout')
          this.willURL = value.pdf
          this.render()
        }
      }
    }
  },
  mounted() {
    if (this.willId) {
      http
        .get('/wills/api/will_detail', { params: { id: this.willId } })
        .then((response) => {
          this.will = response.data
        })
        .catch((error) => {
          console.log(error)
          // if will not found then logout
          this.$store.dispatch('logout')
        })
    }
  },
  computed: {
    pdfDownloadURI() {
      if (this.will && this.will.combined_pdf) return this.will.combined_pdf
      else if (this.will && this.will.pdf) return this.will.pdf
      return ''
    }
  },
  data() {
    return {
      willId: null,
      willURL: '',
      currentPage: [],
      currentPageShown: 1,
      showWill: true,
      pageCount: 1,
      src: null,
      pages: [],
      will: null
    }
  },
  methods: {
    nextPage() {
      var x = Array.from(document.querySelectorAll('.will-pdf'))

      var index = x.findIndex(function (element) {
        return element.className === 'pdf-page-canvas will-pdf view'
      })
      if (typeof x[index + 1] !== 'undefined') {
        x[index + 1].classList.add('view')
        x[index].classList.remove('view')
      }
    },
    previousPage() {
      var x = Array.from(document.querySelectorAll('.will-pdf'))

      var index = x.findIndex(function (element) {
        return element.className === 'pdf-page-canvas will-pdf view'
      })
      if (typeof x[index - 1] !== 'undefined') {
        x[index - 1].classList.add('view')
        x[index].classList.remove('view')
      }
    },
    render() {
      const Worker = require('worker-loader?inline=no-fallback!pdfjs-dist/build/pdf.worker.js')

      import('pdfjs-dist/build/pdf.js').then((pdfjs) => {
        pdfjs.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js';

        var loadingTask = pdfjs.getDocument(this.willURL)
        loadingTask.promise
          .then((pdf) => {
            const pagePromises = range(1, pdf.numPages + 1).map((number) =>
              pdf.getPage(number)
            )
            return Promise.all(pagePromises)
          })
          .then(
            (pages) => {
              var count = 0
              pages.forEach((page) => {
                const viewport = page.getViewport({ scale: 2 })

                // Prepare canvas using PDF page dimensions
                const canvas = document.createElement('canvas')
                canvas.height = viewport.height
                canvas.width = viewport.width

                // Render PDF page into canvas context
                const canvasContext = canvas.getContext('2d')
                const renderContext = {
                  canvasContext,
                  viewport
                }
                page.render(renderContext)
                const div = document.createElement('div')
                if (count === 0) div.className = 'pdf-page-canvas will-pdf view'
                else div.className = 'pdf-page-canvas will-pdf'
                this.$refs['pdf'].appendChild(div).appendChild(canvas)
                count += 1
              })
              this.showAllDone = true
            },
            (error) => console.log('Error', error)
          )
      })
    }
  }
}
</script>

<style scoped>
.pt60 {
  padding-top: 60px;
}
</style>
